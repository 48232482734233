import {fetchJSON, listen} from "../lib/component.js";
import Template from "../lib/template.js";
import Component from "./_component.js";

export default class Select extends Component {

	constructor($, $$, props) {
		super(...arguments, {template: new Template($$.template)});
		listen($, "change", () => !!$.value.length ? this.select(): this.deselect());
		this.load();
	}

	get selectedOption() {
		const offset = this.props.offset ?? 0;
		return !!this.$.value.length ? this.data?.[this.$.selectedIndex - offset]: undefined;
	}

	async load() {
		this.data = await fetchJSON("GET", this.props.url);
		this.template(this.$, this.data);
	}

	select()   {this.dispatch("select", {option: this.selectedOption});}
	deselect() {this.dispatch("deselect");}

};
