import {listen} from "../lib/component.js";
import Template from "../lib/template.js";
import Component, {createImage} from "./_component.js";

export default class Dialog extends Component {

	constructor($, $$, props) {

		super(...arguments);

		if($$.template != null) {
			this.template = new Template($$.template);
		}

		listen(document, "keydown:escape", () => this.$.open && this.close()); // @todo: Make sure this is unlistened or paused after the dialog closes.
		listen(document, "click", event => !event.defaultPrevented && this.$.open && this.close()); // @todo: Make sure this is unlistened or paused after the dialog closes.
		listen($, "click", event => event.stopPropagation());

		$$.close != null && listen($$.close, "click", () => this.close());

	}

	async fill(item) {
		this.$$.content != null && this.template(this.$$.content, item);
		if(this.$$.canvas != null) {
			this.imageDecodeController?.abort();
			this.$$.canvas.replaceChildren();
			const {signal} = this.imageDecodeController = new AbortController();
			const start = Date.now();
			const image = await createImage(item.modelSlug, item.partId, this.$$.canvas, signal).catch(() => {});
			const delay = Date.now() - start;
			await sleep(delay < 100 ? 0 : 250 - delay);
			this.$$.canvas.append(image);
		}
	}

	async open(target, modal=false) {
		this.target?.removeState();
		if(target != null) {
			this.target = target;
			this.target.setState("open");
			this.$.style.setProperty("--dialog-top", `${target.offsetHeight + target.offsetTop + target.offsetParent.offsetTop}px`);
		}
		const open = this.$.open;
		!open && this.$[modal ? "showModal" : "show"]();
		modal && document.documentElement.style.setProperty("--viewport-overflow-y", "hidden");
		// If the dialog was already open, scroll it into view after its animations have completed:
		open && sleep(250, () => this.$.scrollIntoView({behavior: "smooth", block: "nearest"}));
	}

	async close() {
		this.target.removeState();
		this.imageDecodeController?.abort();
		document.documentElement.style.removeProperty("--viewport-overflow-y");
		this.$.setState("closing");
		delete this.target;
		// Reset the dialog content after its animations have completed:
		await sleep(400);
		this.$.close();
		this.$$.content != null && this.$$.content.replaceChildren();
		this.$$.canvas != null && this.$$.canvas.replaceChildren();
		this.$.removeState();
	}

	toggle(target, item) {
		if(target == this.target) {
			this.close();
			return;
		}
		this.fill(item);
		this.open(target);
	}

};
