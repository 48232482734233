import Template from "../lib/template.js";
import Component, {createImage} from "./_component.js";

export default class StockCard extends Component {

	constructor($, $$, props) {
		super(...arguments, {template: new Template($$.template)});
	}

	async fill(item) {
		this.props.dummy.value = item.partId;
		this.props.toggle.value = `${item.modelName} • ${item.modelMaterial}`;
		this.template(this.$$.content, item);
		this.imageDecodeController?.abort();
		this.$$.canvas.replaceChildren();
		this.$$.canvas.removeState();
		const {signal} = this.imageDecodeController = new AbortController();
		const start = Date.now()
		const image = await createImage(item.modelSlug, item.partId, this.$$.canvas, signal).catch(() => {});
		const delay = Date.now() - start;
		await sleep(delay < 100 ? 0 : 250 - delay);
		this.$$.canvas.append(image);
	}

	reset() {
		this.props.dummy.value = "";
		this.props.toggle.value = "";
		this.imageDecodeController?.abort();
		this.$$.canvas.setState("idle");
		this.$$.canvas.replaceChildren();
		this.$$.content.replaceChildren();
	}

};
