import {listen} from "../lib/component.js";
import Component from "./_component.js";

export default class Filter extends Component {

	constructor($, $$, props) {

		super(...arguments);

		listen($, "search", () => this.filter());
		listen($, "input", debounce(250, () => this.filter()));

	}

	filter() {
		// Prevent consecutive filters with the same query:
		const query = String.fromInput(this.$);
		if(query == this.query) {
			return;
		}
		this.query = query;
		this.dispatch("filter", {query});
	}

	clear() {
		this.$.value = "";
	}

};
