import SearchTable from "./_search-table.js";

export default class CardsTable extends SearchTable {

	constructor($, $$, props) {
		super(...arguments);
		this.load();
	}

	get action() {
		// @todo: Rename to "url".
		const depotId = JSON.parse(sessionStorage.lastDepotId ?? null) ?? "@default";
		const url = new URL(`/api/users/@login/depots/${depotId}/cards`, location.origin);
		this.query != null && url.searchParams.set("query", this.query);
		url.searchParams.set("page", this.page);
		return url;
	}

	reset() {
		delete this.query;
		this.page = 1;
		this.load();
		this.dispatch("reset");
	}

};
