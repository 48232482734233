import {fetchJSON, listen} from "../lib/component.js";
import Template from "../lib/template.js";
import Component from "./_component.js";

const $DEFAULT_SORT_BY = "modelName";
const $DEFAULT_SORT_DESC = false;

export default class StockTable extends Component {

	sortBy = $DEFAULT_SORT_BY;
	sortDesc = $DEFAULT_SORT_DESC;

	constructor($, $$, props) {

		super(...arguments, {template: new Template($$.template)});

		listen($$.header, "click", event => this.sort(event.target.closest("th")));
		listen($$.canvas, "click", event => this.toggle(event.target.closest("tr")), {preventDefault: true});

		this.load();

	}

	get action() {
		const depotId = JSON.parse(sessionStorage.lastDepotId ?? null) ?? "@default";
		return `/api/users/@login/depots/${depotId}/stock`;
	}

	async load() {
		this.data = await fetchJSON("GET", this.action);
		this.data.forEach((item, i) => {
			const {partId, modelName, modelMaterial, modelSize, modelLining} = item;
			item.textContent = [partId, modelName, modelMaterial, modelSize, modelLining].join(" ").toLowerCase();
			item.index = i;
		});
		this.props.wrapper != null && this.props.wrapper.style.removeProperty("--wrapper-min-height");
		this.template(this.$$.canvas, this.data);
		this.dispatch("load", {data: this.data});
	}

	sort(column) {
		this.sortDesc = column.dataset.sortBy == this.sortBy ? !this.sortDesc : false;
		this.sortBy = column.dataset.sortBy;
		this.data.sort((a, b) => {
			if(a[this.sortBy] < b[this.sortBy]) { return this.sortDesc ? 1 : -1; }
			if(a[this.sortBy] > b[this.sortBy]) { return this.sortDesc ? -1 : 1; }
			return 0;
		});
		this.$$.canvas.replaceChildren(...this.data.map((item, i) => {
			const row = this.$$.canvas.rows[item.index];
			item.index = i;
			return row;
		}));
		this.$$.header.querySelector('[data-state~="sorted"]')?.removeState();
		column.setState(`sorted sorted-${this.sortDesc ? "descending" : "ascending"}`);
	}

	filter(query) {
		// Freeze the table wrapper height, so it doesn't jank when filtering rows:
		if(this.props.wrapper != null) {
			this.props.wrapper.style.setProperty("--wrapper-min-height", `${this.props.wrapper.offsetHeight}px`);
		}
		if(!query?.length) {
			Array.from(this.$$.canvas.rows).forEach(row => row.show());
		}
		else {
			for(const {index, textContent} of this.data) {
				this.$$.canvas.rows[index].hidden = !textContent.includes(query.toLowerCase());
			}
		}
		this.dispatch("filter", {query});
	}

	toggle(target) {
		this.dispatch("toggle", {target, item: this.data[target.elementIndex]});
	}

	reset() {
		this.load();
		this.dispatch("reset");
	}

};
