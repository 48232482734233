import {listen, fetchJSON} from "../lib/component.js";
import Component from "./_component.js";

export default class StockLookup extends Component {

	constructor($, $$, props) {

		super(...arguments);

		listen($, "keydown:enter", undefined, {preventDefault: true});
		listen($, "search", () => !!$.value.length ? this.lookup() : this.reset());
		listen($, "input", debounce(250, () => $.validity.valid && this.lookup()));

	}

	get url() {
		const depotId = JSON.parse(sessionStorage.lastDepotId ?? null) ?? "@default";
		const url = new URL(`/api/users/@login/depots/${depotId}/stock`, location.origin);
		url.searchParams.set("productionId", this.$.value);
		return url;
	}

	async lookup() {
		this.data = await fetchJSON("GET", this.url);
		const item = this.data.shift();
		if(item?.quantity > 0) {
			this.dispatch("lookup", {item});
		}
		else {
			// this.reset();
			this.$.focus();
			sleep(100, () => alert(this.$.dataset.messageInvalid));
		}
	}

	clear() {
		this.$.value = "";
	}

	reset() {
		this.clear();
		this.dispatch("reset");
	}

};
