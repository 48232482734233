import {listen, fetchJSON} from "../lib/component.js";
import Component from "./_component.js";

export default class Form extends Component {

	constructor($, $$, props) {

		super(...arguments);

		this.$F = $.elements;

		// Get the method from the form attribute, as the native FormElement.method property only supports GET and POST:
		Object.defineProperty($, "method", {
			get: () => $.getAttribute("method") ?? "get"
		});

		$.noValidate = true;

		listen($, "submit", () => !this.frozen && this.validate() && this.submit(), {preventDefault: true});

	}

	validate() {
		const valid = this.$.reportValidity();
		this.$.setState(valid ? "valid" : "invalid");
		return valid;
	}

	async submit() {
		this.frozen = true;
		this.$$.button?.setState("pending");
		this.$$.messageSuccess?.hide();
		this.$$.messageFailure?.hide();
		// Prepare a request:
		const request = {
			method: this.$.method.toUpperCase(),
			url:    new URL(this.$.action, location.origin)
		}
		if(this.serialize != null) {
			switch(request.method) {
				case "GET":
				case "HEAD":
				case "OPTIONS":
					Object.entries(this.serialize()).forEach(([key, value]) => request.url.searchParams.set(key, value));
					break;
				default:
					request.body = this.serialize();
					break;
			}
		}
		try {
			this.data = await fetchJSON(request.method, request.url, request.body);
			this.$$.button?.setState("success");
			this.$$.messageSuccess?.show();
		}
		catch(error) {
			this.$$.button?.setState("failure");
			this.$$.messageFailure?.show();
		}
		finally {
			sleep(this.$$.button != null ? 2500 : 0, () => {
				this.frozen = false;
				this.$$.button?.removeState();
			});
		}
	}

}
